import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export const exportExcel = function (idName, xlsxName) {
  var wb = XLSX.utils.table_to_book(document.querySelector(idName))
  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array',
  })
  try {
    FileSaver.saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      xlsxName
    )
  } catch (e) {
    if (typeof console !== 'undefined') console.log(e, wbout)
  }
  return wbout
}
